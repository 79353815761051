<template>
    <div class="card-tail" v-loading="$waiting.is('loading')">
        <el-table :data="notes" size="medium">
            <el-table-column prop="date" label="Datum" width="300" />
            <el-table-column prop="event" label="Händelse" align="right" width="500" />
            <el-table-column prop="text" label="Text" align="right" />
        </el-table>
    </div>
</template>
<script>
import Api from "./factoring.api";

export default {
    data() {
        return {
            activeComponent: "ClientNotes",
            header: "",
            isSwitchOn: true,
            notes: [],
            selectedFactoringDecisionId: null,
        };
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    async created() {
        this.getClientHeader();
        this.activeComponent = this.$route.meta.activeTab;
        // this.selectedFactoringDecisionId = this.decisions[0].factoringDecisionId;
        var response = await Api.getClientFactoringDecisionNotes(this.$route.params.clientId);
        this.notes = response;
    },

    methods: {
        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        tabClick(event) {
            this.$router.push({ path: "/clientApproved/" + this.$route.params.clientId + "/" + event.name });
        },
        onSwitchChange(value) {
            this.isSwitchOn = value;
        },
    },
};
</script>

<style scoped>
.switch-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.headerAndSwitch {
    display: flex;
    justify-content: space-between;
}

.switch-on {
    --el-switch-on-color: #13ce66;
    --el-switch-off-color: #ff4949;
}

.switch-off {
    --el-switch-on-color: #ff4949;
    --el-switch-off-color: #13ce66;
}
</style>
